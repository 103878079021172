import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      textAlign: 'center',
      margin: [0, 'auto'],
      maxWidth: 750,
      padding: [60, 0],
    },
    title: {
      marginBottom: 66,
    },
    button: {
      maxWidth: 360,
      margin: [20, 'auto', 0],
    },
  },
  { name: 'errorComponent' },
);
