import { useMemo } from 'react';

import { SpinnerProps } from '../../types';
import { calculateStyles } from './logic';

const useSpinner = (size: SpinnerProps['size']) => {
  const { halfSize, radius } = useMemo(() => {
    const halfSizeValue = size / 2;
    return {
      halfSize: halfSizeValue,
      radius: halfSizeValue - 1,
    };
  }, [size]);

  const style = useMemo(() => calculateStyles(radius), [radius]);

  return {
    style,
    halfSize,
    radius,
  };
};

export default useSpinner;
