import { Shadow } from './types';

const boxShadowToString = ({ x, y, blur, spread, color, inset }: Shadow) =>
  `${inset ? 'inset ' : ''}${x}px ${y}px ${blur}px ${spread}px ${color}`;

export default {
  utils: {
    boxShadowToString,
  },
};
