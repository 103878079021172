import { useCallback, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

import config from '../../../../constants/config';
import { AnalyticsEventDetails, AnalyticsEvents } from '../../types';
import { handleOnMount, handleTrackEvent } from './logic';

const useAnalyticsManagement = () => {
  const enabledRef = useRef(false);

  const setEnabledRef = useCallback((isEnabled: boolean) => {
    enabledRef.current = isEnabled;
  }, []);

  useEffect(() => {
    handleOnMount(ReactGA.initialize, config.keys.gAnalytics, setEnabledRef);
  }, [setEnabledRef]);

  const trackEvent = useCallback(
    (eventType: AnalyticsEvents, eventDetails?: AnalyticsEventDetails) =>
      handleTrackEvent(
        enabledRef.current,
        ReactGA.send,
        eventType,
        eventDetails,
      ),
    [],
  );

  return {
    trackEvent,
  };
};

export default useAnalyticsManagement;
