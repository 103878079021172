import Button from '../Button';
import Typography from '../Typography';
import useErrorComponent from './hooks/useErrorComponent';
import useStyles from './styles';
import { ErrorComponentProps } from './types';

const ErrorComponent = ({ resetError }: ErrorComponentProps) => {
  useErrorComponent();
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography className={styles.title} variant="cardTitle">
        Oops!
      </Typography>
      {resetError && (
        <Button
          className={styles.button}
          onClick={resetError}
          variant="bordered"
        >
          Try again
        </Button>
      )}
    </div>
  );
};

export default ErrorComponent;
