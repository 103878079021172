import { createContext, useMemo } from 'react';

import { EMPTY_PROMISE, NOOP } from '../../constants/emptys';
import useStoreManagement from './hooks/useStoreManagement';
import { storeSectionItemStates } from './services/storeOfStores/constants';
import { StoreContextProps, StoreContextValue } from './types';

export const StoreContext = createContext<StoreContextValue>({
  setSection: NOOP,
  subscribeToSectionChanges: () => NOOP,
  getEmptyStoreItem: () => ({
    item: undefined,
    state: storeSectionItemStates.EMPTY,
  }),
  getStoreItem: EMPTY_PROMISE,
  setStoreItemItem: NOOP,
  mergeStoreItemItem: NOOP,
  setStoreItemState: NOOP,
  deleteStoreItem: NOOP,
  subscribeToItemChanges: () => NOOP,
});

export const StoreProvider = ({ children }: StoreContextProps) => {
  const storeManagement = useStoreManagement();
  const value = useMemo(() => storeManagement, [storeManagement]);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};
