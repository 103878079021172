import sizes from './sizes';

const breakpoints = {
  xs: 0,
  sm: 600,
  md: sizes.utils.gridColumns(4, true),
  lg: sizes.utils.gridColumns(6, true),
  xl: 1920,
};

const getValueForKey = (key: keyof typeof breakpoints) => {
  if (breakpoints[key] !== undefined) {
    return breakpoints[key];
  }
  throw new Error(`[breakpoints] key not found: ${key}`);
};

const up = (key: keyof typeof breakpoints) =>
  `@media (min-width: ${getValueForKey(key)}px)`;

const down = (key: keyof typeof breakpoints) =>
  `@media (max-width: ${getValueForKey(key)}px)`;

export default {
  theme: breakpoints,
  context: {
    tabletUpString: up('sm'),
    desktopUpString: up('md'),
    largeUpString: up('lg'),
    extraLargeUpString: up('xl'),
  },
  utils: {
    up,
    down,
  },
};
