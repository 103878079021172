import { useRef } from 'react';

import createStoreOfStores from '../../services/storeOfStores';
import { StoreApi } from '../../services/storeOfStores/types';

const useStoreManagement = () => {
  const storeApiRef = useRef<StoreApi>(createStoreOfStores());

  return storeApiRef.current;
};

export default useStoreManagement;
