import { EMPTY_OBJ } from '../../../../constants/emptys';
import createKeyValueStore from '../../../../services/keyValueStore';
import { Unsubscribe } from '../../../../services/keyValueStore/types';
import createStoreItemApi from './storeItemApi';
import {
  Store,
  StoreApi,
  StoreSection,
  StoreSectionItem,
  StoreSectionKey,
} from './types';

const createStoreOfStores = (): StoreApi => {
  let store = EMPTY_OBJ as Store;

  const getOrCreateStoreSection = <StoreItem>(key: StoreSectionKey) => {
    if (store[key]) {
      return store[key];
    }

    const kvs = createKeyValueStore<StoreSectionItem<StoreItem>>();
    store = {
      ...store,
      [key]: kvs,
    };
    return store[key];
  };

  const getSection = <StoreItem>(key: StoreSectionKey) => {
    const section = getOrCreateStoreSection(key);

    return section as StoreSection<StoreItem | undefined>;
  };

  const setSection = <StoreItem>(
    key: StoreSectionKey,
    value: Record<string, StoreSectionItem<StoreItem | undefined>> | undefined,
  ) => {
    const section = getOrCreateStoreSection(key);

    if (!value) {
      section.resetStore();
      return;
    }
    Object.entries(value).forEach(([itemKey, itemValue]) => {
      section.setValueForKey(itemKey, itemValue);
    });
  };

  const subscribeToSectionChanges = <StoreItem>(
    key: StoreSectionKey,
    subscriptionCallback: (
      items: Record<string, StoreSectionItem<StoreItem | undefined>>,
    ) => void,
  ): Unsubscribe => {
    const section = getSection<StoreItem>(key);
    return section.subscribeToStore(subscriptionCallback);
  };

  const {
    getEmptyStoreItem,
    getStoreItem,
    setStoreItemItem,
    mergeStoreItemItem,
    setStoreItemState,
    deleteStoreItem,
    subscribeToItemChanges,
  } = createStoreItemApi({
    getSection,
    setSection,
  });

  return {
    setSection,
    subscribeToSectionChanges,
    getEmptyStoreItem,
    getStoreItem,
    setStoreItemItem,
    mergeStoreItemItem,
    setStoreItemState,
    deleteStoreItem,
    subscribeToItemChanges,
  };
};

export default createStoreOfStores;
