import { Dispatch, SetStateAction } from 'react';

import { ToastContextToast, ToastDataContextValue } from '../../types';

export const getCloseToast = (
  setTopToasts: Dispatch<SetStateAction<ToastDataContextValue['topToast']>>,
) => {
  setTopToasts(undefined);
};

export const getDeregisterToast = (
  setToasts: Dispatch<SetStateAction<ToastDataContextValue['toasts']>>,
  toastId: ToastContextToast['key'],
) => {
  setToasts((toasts) => toasts.filter((toast) => toast.key !== toastId));
};

export const getRegisterToast = (
  setToasts: Dispatch<SetStateAction<ToastDataContextValue['toasts']>>,
  toastId: ToastContextToast['key'],
  toastConfig: ToastContextToast['config'],
) => {
  setToasts((toasts) => {
    if (toasts.find((toast) => toast.key === toastId)) {
      return toasts;
    }
    return [...toasts, { config: toastConfig, key: toastId }];
  });
};

export const handleToastsChange = (
  toasts: ToastDataContextValue['toasts'],
  setTopToast: Dispatch<SetStateAction<ToastDataContextValue['topToast']>>,
) => {
  if (toasts.length) {
    setTopToast(toasts[toasts.length - 1].key);
    return;
  }
  setTopToast(undefined);
};
