import ReactGA from 'react-ga4';

import { EMPTY_OBJ } from '../../../../constants/emptys';
import {
  AnalyticsEventDetails,
  AnalyticsEvents,
  AnalyticsTrackedEvent,
} from '../../types';

export const handleOnMount = (
  initializeFn: typeof ReactGA.initialize,
  implementationId: string,
  setEnabled: (enabled: boolean) => void,
) => {
  if (initializeFn && implementationId) {
    initializeFn(implementationId);
    setEnabled(true);
  }
};

export const handleTrackEvent = (
  isEnabled: boolean,
  trackEventFn: (event: AnalyticsTrackedEvent) => void,
  eventType: AnalyticsEvents,
  eventDetails?: AnalyticsEventDetails,
) => {
  if (!isEnabled) return;
  trackEventFn({
    ...(eventDetails || EMPTY_OBJ),
    hitType: eventType,
  });
};

// export const generateGAPageEvent = (
//   path: string,
//   title: string,
// ): TrackedEvent => ({
//   hitType: 'pageview',
//   page: path,
//   title,
// });
