import colors from './colors';
import speeds from './speeds';

const backgroundSlide = {
  '0%': {
    backgroundPosition: `
      0 0
    `,
  },
  '100%': {
    backgroundPosition: `
      4000px 0
    `,
  },
};

const spin = {
  '0%': {
    rotate: '0deg',
  },
  '100%': {
    rotate: '360deg',
  },
};

export default {
  context: {
    spin: {
      keyframes: {
        ...spin,
      },
      useage: {
        animationIterationCount: 'infinite',
        animationDuration: speeds.walk,
        animationTimingFunction: 'linear',
      },
    },
    loaderBackgroundPrimary: {
      keyframes: {
        ...backgroundSlide,
      },
      useage: {
        opacity: 0.2,
        background: colors.context.loader.primaryGradient,
        backgroundSize: [[4000, 100]],
        animationIterationCount: 'infinite',
        animationDuration: speeds.walk,
        animationTimingFunction: 'linear',
      },
    },
    loaderBackgroundNeutral: {
      keyframes: {
        ...backgroundSlide,
      },
      useage: {
        opacity: 0.2,
        background: colors.context.loader.neutralGradient,
        backgroundSize: [[4000, 100]],
        animationIterationCount: 'infinite',
        animationDuration: speeds.walk,
        animationTimingFunction: 'linear',
      },
    },
  },
};
