import { memo } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';

import useFade from './hooks/useFade';
import { FadeProps } from './types';

const defaultStyle = (duration: number) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
});

const transitionStyles: {
  [key in TransitionStatus]: React.CSSProperties;
} = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const Fade = ({
  className,
  durationIn = 1000,
  durationOut = 1000,
  in: inProp,
  children,
  onClick,
  onExited,
}: FadeProps) => {
  const { ref } = useFade();

  return (
    <Transition
      in={inProp}
      nodeRef={ref}
      timeout={{
        enter: durationIn,
        exit: durationOut,
      }}
      unmountOnExit
      onExited={onExited}
    >
      {(state) => (
        <div
          ref={ref}
          className={className}
          onClick={onClick}
          style={{
            ...defaultStyle(inProp ? durationIn : durationOut),
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default memo(Fade);
