/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

import { cachedRequestFactory } from '../../../../services/requests/cachedRequests';
import { RequestCacheReset } from '../../../../services/requests/cachedRequests/types';
import { constructIdempotencyKey } from '../../../../services/requests/logic';
import {
  RequestConfig,
  RequestPerformer,
} from '../../../../services/requests/types';
import { useErrorCapture } from '../../../ErrorCaptureContext';
import useToastApi from '../../../ToastContext/hooks/useToastApi';
import { ServiceRequestName } from '../../types';
import { addServiceBaseUrlToConfig, launchErrorToast } from './logic';

class RequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RequestError';
  }
}

const usePlatformRequest = () => {
  const performCachedRequest = useRef<[RequestPerformer, RequestCacheReset]>(
    cachedRequestFactory(),
  );
  const errorCapture = useErrorCapture();
  const { registerToast } = useToastApi();

  const performRequest = useCallback(
    async <ResponseType>(config: RequestConfig) => {
      const response = await performCachedRequest.current[0]<ResponseType>(
        config,
      );
      return response.data;
    },
    [],
  );

  const errorCapturingRequest = useCallback(
    async <ResponseType>(config: RequestConfig) => {
      try {
        return await performRequest<ResponseType>(config);
      } catch (e: any) {
        if (
          e?.constructor?.name === 'CanceledError' ||
          e.message === 'canceled' ||
          e.message === 'Request aborted'
        ) {
          return Promise.reject(e);
        }

        launchErrorToast(registerToast, e, constructIdempotencyKey(config));

        try {
          throw new RequestError(e.message);
        } catch (error) {
          errorCapture.error(`Request Error - ${e.message}`, error as Error, {
            message: e.message,
            body: e?.response?.data?.errorText || e.message,
            url: e.request.responseURL,
            config,
          });
        }
        throw e;
      }
    },
    [performRequest, registerToast, errorCapture],
  );

  const performPlatformRequest = useCallback(
    async <ResponseType>(
      serviceName: ServiceRequestName,
      config: RequestConfig,
    ) => {
      const serviceRequestConfig = addServiceBaseUrlToConfig(
        serviceName,
        config,
      );
      return errorCapturingRequest<ResponseType>(serviceRequestConfig);
    },
    [errorCapturingRequest],
  );

  return performPlatformRequest;
};

export default usePlatformRequest;
