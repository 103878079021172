import animations from './animations';
import breakpoints from './breakpoints';
import colors from './colors';
import fonts from './fonts';
import shadows from './shadows';
import sizes from './sizes';
import speeds from './speeds';
import transitions from './transitions';
import utils from './utils';
import zIndex from './zIndex';

export default {
  animations,
  breakpoints,
  colors,
  fonts,
  shadows,
  sizes,
  speeds,
  transitions,
  utils,
  zIndex,
};
