import { useCallback, useEffect, useState } from 'react';

import { ToastContextToast, ToastDataContextValue } from '../../types';
import {
  getCloseToast,
  getDeregisterToast,
  getRegisterToast,
  handleToastsChange,
} from './logic';

const useToastManagement = () => {
  const [topToast, setTopToast] = useState<ToastDataContextValue['topToast']>();
  const [toasts, setToasts] = useState<ToastDataContextValue['toasts']>([]);

  const closeToast = useCallback(
    () => getCloseToast(setTopToast),
    [setTopToast],
  );

  const registerToast = useCallback(
    (
      toastId: ToastContextToast['key'],
      toastConfig: ToastContextToast['config'],
    ) => getRegisterToast(setToasts, toastId, toastConfig),
    [setToasts],
  );

  const deregisterToast = useCallback(
    (toastId: ToastContextToast['key']) =>
      getDeregisterToast(setToasts, toastId),
    [setToasts],
  );

  useEffect(
    () => handleToastsChange(toasts, setTopToast),
    [toasts, setTopToast],
  );

  return {
    registerToast,
    closeToast,
    deregisterToast,
    topToast,
    toasts,
  };
};

export default useToastManagement;
