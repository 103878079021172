import styling from '../../../../services/styling';
import useMediaQueryMonitor from '../useMediaQueryMonitor';

const useMediaQueryManagement = () => {
  const isXs = useMediaQueryMonitor(styling.breakpoints.utils.up('xs'));
  const isSm = useMediaQueryMonitor(styling.breakpoints.utils.up('sm'));
  const isMd = useMediaQueryMonitor(styling.breakpoints.utils.up('md'));
  const isLg = useMediaQueryMonitor(styling.breakpoints.utils.up('lg'));
  const isXl = useMediaQueryMonitor(styling.breakpoints.utils.up('xl'));
  const isTablet = useMediaQueryMonitor(
    styling.breakpoints.context.tabletUpString,
  );
  const isDesktop = useMediaQueryMonitor(
    styling.breakpoints.context.desktopUpString,
  );
  const largeUpString = useMediaQueryMonitor(
    styling.breakpoints.context.largeUpString,
  );

  return {
    isXs,
    isSm: isSm && isXs,
    isMd: isMd && isSm && isXs,
    isLg: isLg && isMd && isSm && isXs,
    isXl: isXl && isLg && isMd && isSm && isXs,
    isTablet,
    isDesktop,
    largeUpString,
  };
};

export default useMediaQueryManagement;
