import { createContext, useMemo } from 'react';

import { EMPTY_PROMISE } from '../../constants/emptys';
import useExternalRequest from './hooks/useExternalRequest';
import usePlatformRequest from './hooks/usePlatformRequest';
import { RequestContextValue, RequestsContextProps } from './types';

export const RequestsContext = createContext<RequestContextValue>({
  externalRequest: EMPTY_PROMISE,
  platformRequest: EMPTY_PROMISE,
});

export const RequestsProvider = ({ children }: RequestsContextProps) => {
  const platformRequest = usePlatformRequest();
  const externalRequest = useExternalRequest();
  const value = useMemo(
    () => ({ externalRequest, platformRequest }),
    [externalRequest, platformRequest],
  );

  return (
    <RequestsContext.Provider value={value}>
      {children}
    </RequestsContext.Provider>
  );
};
