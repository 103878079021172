import { createContext, useMemo } from 'react';

import { NOOP } from '../../constants/emptys';
import useToastManagement from './hooks/useToastManagement';
import {
  ToastContextProps,
  ToastContextValue,
  ToastDataContextValue,
} from './types';

export const ToastContext = createContext<ToastContextValue>({
  closeToast: NOOP,
  registerToast: NOOP,
  deregisterToast: NOOP,
});

export const ToastDataContext = createContext<ToastDataContextValue>({
  topToast: undefined,
  toasts: [],
});

export const ToastProvider = ({ children }: ToastContextProps) => {
  const { toasts, topToast, ...toastManagement } = useToastManagement();
  const apiValue = useMemo(() => toastManagement, [toastManagement]);
  const dataValue = useMemo(() => ({ toasts, topToast }), [toasts, topToast]);

  return (
    <ToastContext.Provider value={apiValue}>
      <ToastDataContext.Provider value={dataValue}>
        {children}
      </ToastDataContext.Provider>
    </ToastContext.Provider>
  );
};
