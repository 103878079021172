import { Unsubscribe } from '../../../../../services/keyValueStore/types';
import logger from '../../../../../services/logger';
import { storeSectionItemStates } from '../constants';
import {
  StoreSection,
  StoreSectionFetcherGetItemById,
  StoreSectionItem,
  StoreSectionItemState,
  StoreSectionKey,
} from '../types';

const createStoreItemApi = ({
  getSection,
}: {
  getSection: <StoreItem>(
    key: StoreSectionKey,
  ) => StoreSection<StoreItem | undefined>;
  setSection: <StoreItem>(
    key: StoreSectionKey,
    value: Record<string, StoreSectionItem<StoreItem | undefined>>,
  ) => void;
}) => {
  const getEmptyStoreItem = () =>
    ({
      item: undefined,
      state: storeSectionItemStates.EMPTY,
    } as StoreSectionItem<undefined>);

  const subscribeToItemChanges = <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    subscriptionCallback: (
      item: StoreSectionItem<StoreItem | undefined>,
    ) => void,
  ): Unsubscribe => {
    const section = getSection<StoreItem>(sectionKey);
    const alwaysPresentCallback = (
      item: StoreSectionItem<StoreItem | undefined> | undefined,
    ) => {
      if (!item) {
        subscriptionCallback(getEmptyStoreItem());
        return;
      }
      subscriptionCallback(item);
    };
    return section.subscribeToItem(id, alwaysPresentCallback);
  };

  const setStoreItemItem = <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    item: StoreItem | undefined,
  ) => {
    const section = getSection<StoreItem>(sectionKey);
    const sectionItem = section.getValueForKey(id);
    if (!item) {
      section.setValueForKey(id, {
        state: sectionItem?.state || storeSectionItemStates.EMPTY,
        item: undefined,
      });
    } else {
      section.setValueForKey(id, {
        state: storeSectionItemStates.READY,
        item,
      });
    }
  };

  const mergeStoreItemItem = <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    item: Partial<StoreItem>,
  ) => {
    const section = getSection<StoreItem>(sectionKey);
    const sectionItem = section.getValueForKey(id);
    if (sectionItem?.item) {
      section.setValueForKey(id, {
        state: storeSectionItemStates.READY,
        item: {
          ...sectionItem.item,
          ...item,
        },
      });
    } else {
      throw new Error(
        `[StoreItem] You cannot update an item that does not exist yet: ${sectionKey} -> ${id}`,
      );
    }
  };

  const setStoreItemState = (
    sectionKey: StoreSectionKey,
    id: string,
    state: StoreSectionItemState,
  ) => {
    const section = getSection(sectionKey);
    const sectionItem = section.getValueForKey(id);
    section.setValueForKey(id, {
      item: sectionItem?.item,
      state,
    });
  };

  const deleteStoreItem = (sectionKey: StoreSectionKey, id: string) => {
    const section = getSection(sectionKey);
    section.deleteValueForKey(id);
  };

  const fetchStoreItem = async <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string,
    getItemFetcher: StoreSectionFetcherGetItemById<StoreItem | undefined>,
  ) => {
    logger.log('fetching item', sectionKey, id);
    setStoreItemState(sectionKey, id, storeSectionItemStates.LOADING);
    const responseItem = await getItemFetcher(id);
    if (responseItem) {
      setStoreItemItem<StoreItem>(sectionKey, id, responseItem);
      setStoreItemState(sectionKey, id, storeSectionItemStates.READY);
      return {
        item: responseItem,
        state: storeSectionItemStates.READY,
      };
    }
    const emptyStoreItem = getEmptyStoreItem();
    setStoreItemItem<StoreItem>(sectionKey, id, emptyStoreItem.item);
    setStoreItemState(sectionKey, id, emptyStoreItem.state);
    return emptyStoreItem;
  };

  const getStoreItem = async <StoreItem>(
    sectionKey: StoreSectionKey,
    id: string | undefined,
    getItemFetcher:
      | StoreSectionFetcherGetItemById<StoreItem | undefined>
      | undefined,
  ) => {
    if (!id) return getEmptyStoreItem();
    const section = getSection<StoreItem>(sectionKey);
    let sectionItem = section.getValueForKey(id);
    // if we have an item
    if (sectionItem) {
      return sectionItem;
    }
    if (!getItemFetcher) {
      return getEmptyStoreItem();
    }
    sectionItem = await fetchStoreItem<StoreItem>(
      sectionKey,
      id,
      getItemFetcher,
    );
    return sectionItem;
  };

  return {
    getEmptyStoreItem,
    getStoreItem,
    setStoreItemItem,
    mergeStoreItemItem,
    setStoreItemState,
    deleteStoreItem,
    subscribeToItemChanges,
  };
};

export default createStoreItemApi;
