import { memo } from 'react';

import useSpinner from './hooks/useSpinner';
import useStyles from './styles';
import { SpinnerProps } from './types';

const Spinner = ({ size }: SpinnerProps) => {
  const { style, halfSize, radius } = useSpinner(size);
  const styles = useStyles();

  return (
    <div className={styles.container} style={style}>
      <svg className={styles.spinner} height={size} width={size}>
        <defs>
          <linearGradient
            id="b"
            x1=".69"
            y1="20.76"
            x2="20.76"
            y2="53.01"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#0095b8" />
            <stop offset=".21" stopColor="#2489ca" />
            <stop offset=".58" stopColor="#5e78e6" />
            <stop offset=".85" stopColor="#836df8" />
            <stop offset="1" stopColor="#9169ff" />
          </linearGradient>
        </defs>
        <circle
          className={styles.circle}
          cx={halfSize}
          cy={halfSize}
          fill="none"
          r={radius}
          stroke="url(#b)"
          strokeMiterlimit="10"
          strokeWidth={1}
        />
      </svg>
    </div>
  );
};

export default memo(Spinner);
