import { useMemo } from 'react';

import { ButtonProps } from '../../types';
import { getButtonComponent } from './logic';

const useButton = ({
  href,
  to,
  onClick,
  isDisabled,
  isLoading,
  size = 'md',
}: {
  href: ButtonProps['href'];
  to: ButtonProps['to'];
  onClick: ButtonProps['onClick'];
  isDisabled: ButtonProps['isDisabled'];
  isLoading: ButtonProps['isLoading'];
  size: ButtonProps['size'];
}) => {
  const { as: Component, ...componentProps } = useMemo(
    () =>
      getButtonComponent({
        href,
        to,
        onClick,
        isDisabled,
        isLoading,
      }),
    [href, to, onClick, isDisabled, isLoading],
  );

  const spinnerSize = useMemo(() => {
    if (size === 'md') return 28;
    return 20;
  }, [size]);

  return { Component, componentProps, spinnerSize };
};

export default useButton;
