import { useContext } from 'react';

import { ToastContext } from '../..';

const useToastApi = () => {
  const { registerToast, deregisterToast, closeToast } =
    useContext(ToastContext);

  return {
    registerToast,
    deregisterToast,
    closeToast,
  };
};

export default useToastApi;
