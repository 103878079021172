import { CSSProperties } from 'react';

export const calculateStyles = (radius: number) => {
  const circumference = Math.floor(Math.PI * radius * 2);

  return {
    '--circumference': circumference,
    '--circumference70': circumference * 0.7,
    '--circumference160': circumference * 1.6,
  } as CSSProperties;
};
