import { createContext, useMemo } from 'react';

import { EMPTY_ARR, NOOP } from '../../constants/emptys';
import useModalManagement from './hooks/useModalManagement';
import {
  ModalContextProps,
  ModalContextValue,
  ModalDataContextValue,
} from './types';

export const ModalContext = createContext<ModalContextValue>({
  closeModal: NOOP,
  registerModal: NOOP,
  deregisterModal: NOOP,
});

export const ModalDataContext = createContext<ModalDataContextValue>({
  topModal: undefined,
  modals: EMPTY_ARR,
});

export const ModalProvider = ({ children }: ModalContextProps) => {
  const { topModal, modals, ...modalManagement } = useModalManagement();
  const apiValue = useMemo(() => modalManagement, [modalManagement]);
  const dataValue = useMemo(() => ({ modals, topModal }), [modals, topModal]);

  return (
    <ModalContext.Provider value={apiValue}>
      <ModalDataContext.Provider value={dataValue}>
        {children}
      </ModalDataContext.Provider>
    </ModalContext.Provider>
  );
};
