import { createUseStyles } from 'react-jss';

import styling from '../../../services/styling';

export default createUseStyles(
  {
    '@keyframes spin': {
      ...styling.animations.context.spin.keyframes,
    },
    '@keyframes dashPulse': {
      '0%': {
        strokeDasharray: `1, var(--circumference160)`,
        strokeDashoffset: `0`,
      },
      '50%': {
        strokeDasharray: `var(--circumference70), var(--circumference160)`,
        strokeDashoffset: `calc(var(--circumference) * -0.28px)`,
      },
      '100%': {
        strokeDasharray: `var(--circumference70), var(--circumference160)`,
        strokeDashoffset: `calc(var(--circumference) * -1px)`,
      },
    },
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    spinner: {
      animationName: '$spin',
      ...styling.animations.context.spin.useage,
    },
    circle: {
      strokeDasharray: `1, var(--circumference)`,
      strokeDashoffset: 0,
      animationName: '$dashPulse',
      animationIterationCount: 'infinite',
      animationDuration: styling.speeds.walk,
      animationTimingFunction: 'ease',
    },
  },
  {
    name: 'spinner',
  },
);
