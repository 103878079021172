import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import GlobalStyles from './components/GlobalStyles';
import { isLanding } from './constants/environment';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { ErrorCaptureProvider } from './contexts/ErrorCaptureContext';
import { MediaQueryManagementProvider } from './contexts/MediaQueryManagementContext';
import { ModalProvider } from './contexts/ModalContext';
import { RequestsProvider } from './contexts/RequestsContext';
import { StoreProvider } from './contexts/StoreContext';
import { ToastProvider } from './contexts/ToastContext';

const LandingPage = lazy(() => import('./scenes/landing'));
const App = lazy(() => import('./app'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <GlobalStyles />
    <ErrorCaptureProvider>
      <AnalyticsProvider>
        <MediaQueryManagementProvider>
          <ToastProvider>
            <ModalProvider>
              <RequestsProvider>
                <StoreProvider>
                  {isLanding && (
                    <Suspense>
                      <LandingPage />
                    </Suspense>
                  )}
                  {!isLanding && (
                    <Suspense>
                      <App />
                    </Suspense>
                  )}
                </StoreProvider>
              </RequestsProvider>
            </ModalProvider>
          </ToastProvider>
        </MediaQueryManagementProvider>
      </AnalyticsProvider>
    </ErrorCaptureProvider>
  </StrictMode>,
);
