export type AnalyticsContextProps = {
  children: React.ReactNode;
};

export enum AnalyticsEvents {
  PageView = 'pageview',
}

export type AnalyticsEventDetails = {
  [key: string]: unknown;
};

export type AnalyticsTrackedEvent = {
  hitType: AnalyticsEvents;
  [key: string]: unknown;
};

export type AnalyticsApi = {
  trackEvent: (
    event: AnalyticsEvents,
    eventProperties?: AnalyticsEventDetails,
  ) => void;
};
