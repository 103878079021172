import { tracking } from './fontTracking';
import { transformFonts } from './transformFonts';

const instrumentSans = {
  fontFamily: `'Instrument Sans', Helvetica, Arial, sans-serif`,
  weights: {
    bold: 700,
    medium: 500,
    regular: 400,
  },
  sizes: {
    '3xl': {
      fontSize: '3.6rem',
      lineHeight: '4.32rem',
      letterSpacing: tracking(36, -19),
    },
    '2xl': {
      fontSize: '2.4rem',
      lineHeight: '3rem',
      letterSpacing: tracking(24, -19),
    },
    xl: {
      fontSize: '1.685rem',
      lineHeight: '2.021rem',
      letterSpacing: 0,
    },
    lg: {
      fontSize: '1.6rem',
      lineHeight: '2.368rem',
      letterSpacing: 0,
    },
    md: {
      fontSize: '1.579rem',
      lineHeight: '2.368rem',
      letterSpacing: 0,
    },
    sm: {
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      letterSpacing: 0,
    },
    xs: {
      fontSize: '1.455rem',
      lineHeight: '1.746rem',
      letterSpacing: 0,
    },
    '2xs': {
      fontSize: '1.363rem',
      lineHeight: '1.636rem',
      letterSpacing: 0,
    },
    '3xs': {
      fontSize: '1.2rem',
      lineHeight: '2.468rem',
      letterSpacing: 0,
    },
  },
};

export default transformFonts({
  instrumentSans,
});
