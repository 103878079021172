import utils from './utils';

const spacing = {
  '3xs': 6,
  '2xs': 12,
  xs: 18,
  sm: 24,
  md: 36,
  lg: 48,
  xl: 72,
  '2xl': 105,
  '3xl': 144,
};

const columnWidth = 180;
const columnGutters = spacing.xl;

const gridColumns = (cols: number, includeEdgeGutters = false) =>
  columnWidth * cols +
  (cols - 1) * columnGutters +
  (includeEdgeGutters ? 2 * columnGutters : 0);

const containerQuery = (
  direction: 'width' | 'height',
  min: number,
  max?: number,
) =>
  `@container ((min-${direction}: ${utils.px(min)})${
    max ? ` and (max-${direction}: ${utils.px(max)}))` : ')'
  }`;

export default {
  theme: {
    spacing,
  },
  context: {
    minWidth: gridColumns(2, true),
    maxWidth: gridColumns(6, false),
    columnWidth,
    columnGutters,
    gutteringW: spacing.sm,
    modal: {
      radius: spacing['2xs'],
    },
    cards: {
      width: gridColumns(2, false),
      gutters: columnGutters,
      radius: spacing.xs,
    },
  },
  utils: {
    gridColumns,
    containerQuery,
  },
};
