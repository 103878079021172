import { memo } from 'react';

import useStyles from './styles';
import useNormalizeStyles from './stylesNormalize';

const GlobalStyles = () => {
  useNormalizeStyles();
  useStyles();

  return null;
};

export default memo(GlobalStyles);
