import { EMPTY_OBJ } from '../../constants/emptys';
import typographyConstants from './constants';
import { TypographyProps } from './types';

export const getTypographyStyles = (
  maxLines: TypographyProps['maxLines'],
  variant: TypographyProps['variant'] = 'body',
  style: TypographyProps['style'] = EMPTY_OBJ,
) =>
  ({
    ...style,
    '--typographyMaxLines': maxLines || '',
    '--typographyLineHeight': maxLines
      ? typographyConstants[variant].lineHeight
      : '',
  } as React.CSSProperties);
