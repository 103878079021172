/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import { ExternalLinkProps } from './types';

const ExternalLink = ({ children, type, ...props }: ExternalLinkProps) => (
  <a {...props}>{children}</a>
);

export default memo(ExternalLink);
