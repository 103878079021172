/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import Fade from '../Animations/Fade';
import Spinner from '../Loaders/Spinner';
import useButton from './hooks/useButton';
import useStyles from './styles';
import { ButtonProps } from './types';

const Button = ({
  className,
  children,
  href,
  to,
  onClick,
  isDisabled,
  isHovered,
  isLoading,
  size = 'md',
  variant,
  ...rest
}: ButtonProps) => {
  const styles = useStyles();
  const { Component, componentProps, spinnerSize } = useButton({
    href,
    to,
    onClick,
    isDisabled,
    isLoading,
    size,
  });

  return (
    <Component
      {...rest}
      {...componentProps}
      className={clsx(
        className,
        styles.button,
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        isHovered && styles.isHovered,
        isDisabled && styles.disabled,
        isLoading && styles.loading,
      )}
    >
      <span className={styles.contentContainer}>
        <span className={styles.content}>{children}</span>
        <Fade
          className={styles.loadingSpinner}
          in={!!isLoading}
          durationIn={300}
          durationOut={300}
        >
          <Spinner size={spinnerSize} />
        </Fade>
      </span>
    </Component>
  );
};

export default memo(Button);
