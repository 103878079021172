import styling from '../../services/styling';
import breakpoints from '../../services/styling/breakpoints';
import { tracking } from '../../services/styling/fontTracking';

const inherit = {
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  letterSpacing: 'inherit',
};

const footerLicense = {
  ...styling.fonts.instrumentSans.regular['3xs'],
};

const input = {
  ...styling.fonts.instrumentSans.regular.xs,
};

const navigationLinks = {
  ...styling.fonts.instrumentSans.medium.sm,
};

const body = {
  ...styling.fonts.instrumentSans.regular.md,
};

const directoryCardDetailTitle = {
  ...styling.fonts.instrumentSans.bold.md,
};

const headerSmallest = {
  ...styling.fonts.instrumentSans.medium.lg,
};

const categories = {
  ...styling.fonts.instrumentSans.bold.lg,
  letterSpacing: tracking(16, 250),
};

const cardContent = {
  ...styling.fonts.instrumentSans.regular.lg,
};

const categoryFilters = {
  ...styling.fonts.instrumentSans.medium.xl,
};

const footerLinks = {
  ...styling.fonts.instrumentSans.medium.xl,
};

const headerSmall = {
  ...styling.fonts.instrumentSans.regular['2xl'],
};

const quote = {
  ...styling.fonts.instrumentSans.medium['2xl'],
};

const cardTitle = {
  ...styling.fonts.instrumentSans.medium['2xl'],
};

const directoryCardTitle = {
  ...styling.fonts.instrumentSans.bold['2xl'],
};

const header = {
  ...styling.fonts.instrumentSans.medium['3xl'],
};

const typographyConstants = {
  inherit,
  footerLicense,
  input,
  navigationLinks,
  body,
  directoryCardDetailTitle,
  headerSmallest,
  categories,
  cardContent,
  categoryFilters,
  footerLinks,
  headerSmall,
  quote,
  cardTitle,
  directoryCardTitle,
  header,
} as const;

export default typographyConstants;
