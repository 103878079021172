import { Dispatch, SetStateAction } from 'react';

import { Modal, ModalDataContextValue } from '../../types';

export const getCloseModal = (
  setTopModal: Dispatch<SetStateAction<ModalDataContextValue['topModal']>>,
) => {
  setTopModal(undefined);
  window.document.scrollingElement?.classList.remove('lock-scroll-modal');
};

export const getDeregisterModal = (
  setModals: Dispatch<SetStateAction<ModalDataContextValue['modals']>>,
  modalId: string,
) => {
  setModals((modals) => {
    const currentModal = modals.find((modal) => modal.key === modalId);
    currentModal?.config?.onClose?.();
    return modals.filter((modal) => modal.key !== modalId);
  });
};

export const getRegisterModal = (
  setModals: Dispatch<SetStateAction<ModalDataContextValue['modals']>>,
  modalId: string,
  modalConfig: Modal,
) => {
  setModals((modals) => {
    if (modals.find((modal) => modal.key === modalId)) {
      return modals;
    }
    return [...modals, { config: modalConfig, key: modalId }];
  });
};

export const handleModalsChange = (
  modals: ModalDataContextValue['modals'],
  setTopModal: Dispatch<SetStateAction<ModalDataContextValue['topModal']>>,
) => {
  if (modals.length) {
    setTopModal(modals[modals.length - 1].key);
    window.document.scrollingElement?.classList.add('lock-scroll-modal');
    return;
  }
  setTopModal(undefined);
};
