import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { RegisterCancelFn, RequestConfig, RequestPerformer } from './types';

export const constructIdempotencyKey = ({
  method = 'GET',
  url,
  data,
}: RequestConfig): string => `${method}|${url}|${JSON.stringify(data)}`;

export const apiRequestBase =
  (performRequest: RequestPerformer) =>
  async <ResponseType>(
    requestConfig: RequestConfig,
    registerCancel: RegisterCancelFn = () => undefined,
  ) => {
    const controller = new AbortController();
    registerCancel(() => controller.abort());

    try {
      const response = await performRequest<ResponseType>({
        ...requestConfig,
        signal: controller.signal,
      });

      return response;
    } catch (err) {
      if (err?.constructor?.name === 'AbortError') {
        return Promise.reject(err);
      }

      throw err;
    }
  };

const appendCachebreak = (config: InternalAxiosRequestConfig) => {
  const { method, url, ...rest } = config;
  if (!method || method.toLowerCase() !== 'get' || !url) {
    return config;
  }
  if (url.indexOf('?') >= 0) {
    return {
      ...rest,
      method,
      url: `${url}&v=${process.env.REACT_APP_CACHEBREAK}`,
    };
  }
  return {
    ...rest,
    method,
    url: `${url}?v=${process.env.REACT_APP_CACHEBREAK}`,
  };
};

export const addInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(appendCachebreak);
};
