import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    button: {
      ...styling.utils.buttonReset(),
      display: 'inline-block',
      boxSizing: 'border-box',
      overflow: 'hidden',
      borderRadius: '1000rem',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:not($disabled):not($loading):hover $contentContainer': {
        background: styling.colors.theme.neutral100,
        color: styling.colors.theme.primary600,
      },
    },
    'variant-bordered': {
      padding: 1,
      background: styling.colors.context.gradients.highlightGradient,
    },
    'variant-plain': {
      padding: 0,
    },
    'size-md': {
      ...styling.fonts.instrumentSans.medium.xl,
      height: styling.sizes.theme.spacing.xl,
      minWidth: 300,
      '& $contentContainer': {
        padding: [0, styling.sizes.theme.spacing.lg],
      },
      [styling.breakpoints.context.tabletUpString]: {
        ...styling.fonts.instrumentSans.medium['2xs'],
        height: styling.sizes.theme.spacing.lg,
        minWidth: 204,
        '& $contentContainer': {
          padding: [0, styling.sizes.theme.spacing.lg / 2],
        },
      },
    },
    'size-sm': {
      ...styling.fonts.instrumentSans.medium.xl,
      height: styling.sizes.theme.spacing.lg,
      minWidth: 180,
      '& $contentContainer': {
        padding: [0, styling.sizes.theme.spacing.md],
      },
      [styling.breakpoints.context.tabletUpString]: {
        ...styling.fonts.instrumentSans.medium.xl,
        height: styling.sizes.theme.spacing.md,
        minWidth: 124,
        '& $contentContainer': {
          padding: [0, styling.sizes.theme.spacing.md / 2],
        },
      },
    },
    isHovered: {
      '&:not($disabled):not($loading) $contentContainer': {
        background: styling.colors.theme.neutral100,
        color: styling.colors.theme.primary600,
      },
    },
    disabled: {
      cursor: 'not-allowed',
      '& $content': {
        opacity: 0.4,
      },
    },
    loading: {
      cursor: 'progress',
      '& $content': {
        opacity: 0,
      },
    },
    contentContainer: {
      position: 'relative',
      height: '100%',
      borderRadius: '1000rem',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: styling.colors.theme.neutral050,
      background: styling.colors.theme.primary600,
      transition: styling.transitions.utils.generate(
        ['background-color'],
        ['color'],
        ['opacity'],
      ),
    },
    content: {},
    loadingSpinner: {
      ...styling.utils.absoluteFull(),
      pointerEvents: 'none',
    },
  },
  { name: 'button' },
);
