import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';

export default createUseStyles(
  {
    '@global': {
      '*': {
        boxSizing: 'border-box',
        // Firefox
        '&::-moz-placeholder': {
          color: styling.colors.context.inputs.placeholder,
          opacity: 1, // Override Firefox's unusual default opacity, see https://github.com/twbs/bootstrap/pull/11526
        },
        '&:-moz-placeholder': {
          color: styling.colors.context.inputs.placeholder,
          opacity: 1, // Override Firefox's unusual default opacity, see https://github.com/twbs/bootstrap/pull/11526
        },
        '&:-ms-input-placeholder': {
          color: styling.colors.context.inputs.placeholder,
        }, // Internet Explorer 10+
        '&::-webkit-input-placeholder': {
          color: styling.colors.context.inputs.placeholder,
        }, // Safari and Chrome
        '&::placeholder': {
          color: styling.colors.context.inputs.placeholder,
        },
      },
      html: {
        fontSize: 'calc(62.5% * var(--typographyScale))',
        height: '-webkit-fill-available',
        minWidth: styling.sizes.context.minWidth,
      },
      body: {
        ...styling.fonts.instrumentSans.regular.md,
        backgroundColor: styling.colors.context.body.background,
        color: styling.colors.context.body.color,
        minWidth: styling.sizes.context.minWidth,
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        '&::-webkit-scrollbar': {
          WebkitAppearance: 'none',
        },
        '&::-webkit-scrollbar:vertical': {
          backgroundColor: '#fafafa',
          width: 'var(--scrollbarWidth)',
          borderLeft: '1px solid #e7e7e7',
        },

        '&::-webkit-scrollbar:horizontal': {
          backgroundColor: '#fafafa',
          height: 'var(--scrollbarHeight)',
          borderTop: '1px solid #e7e7e7',
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: 8,
          border:
            '3px solid #fafafa' /* should match background, can't be transparent */,
          backgroundColor: '#c7c7c7',
        },

        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#878787',
        },
      },
      '.lock-scroll-modal': {
        overflow: 'hidden',
      },
      a: {
        color: 'inherit',
      },
      p: {
        margin: 0,
      },
      ul: {
        padding: 0,
        margin: 0,
        listStyle: 'none',
      },
    },
  },
  { name: 'global' },
);
