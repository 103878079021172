import { NOOP } from '../../../../constants/emptys';
import ExternalLink from '../../../ExternalLink';
import InternalLink from '../../../InternalLink';
import { ButtonProps } from '../../types';

export const getButtonComponent = ({
  href,
  to,
  onClick,
  isDisabled,
  isLoading,
}: {
  href: ButtonProps['href'];
  to: ButtonProps['to'];
  onClick: ButtonProps['onClick'];
  isDisabled: ButtonProps['isDisabled'];
  isLoading: ButtonProps['isLoading'];
}) => {
  if (onClick) {
    return {
      as: 'button' as React.ElementType,
      disabled: isDisabled || isLoading,
      onClick: isDisabled || isLoading ? NOOP : onClick,
      href: undefined,
      to: undefined,
    };
  }
  if (href) {
    return {
      as: (isDisabled ? 'div' : ExternalLink) as React.ElementType,
      href: isDisabled ? undefined : href,
    };
  }
  return {
    as: (isDisabled ? 'div' : InternalLink) as React.ElementType,
    to: isDisabled ? undefined : to,
  };
};
