const px = (num: number | number[]): string => {
  if (typeof num === 'number') {
    return `${num}px`;
  }
  return num.map((val: number) => px(val)).join(' ');
};

const buttonReset = () => ({
  background: 'none',
  padding: 0,
  border: 'none',
  outline: 'none',
  color: 'inherit',
  cursor: 'pointer',
  appearance: 'none',
});

const absoluteFull = () => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const userDrag = (value = 'none') => ({
  WebkitUserDrag: value,
  KhtmlUserDrag: value,
  MozUserDrag: value,
  OUserDrag: value,
  userDrag: value,
});

export default {
  absoluteFull,
  buttonReset,
  px,
  userDrag,
};
