import { createUseStyles } from 'react-jss';

import styling from '../../services/styling';
import typographyConstants from './constants';

export default createUseStyles(
  {
    '@global': {
      ':root': {
        '--typographyScale': 1.2,
      },
      [styling.breakpoints.context.tabletUpString]: {
        ':root': {
          '--typographyScale': 1,
        },
      },
    },
    typography: {
      '& strong': {
        fontWeight: 700,
      },
      '& em': {
        fontStyle: 'italic',
      },
    },
    maxLines: {
      overflow: 'hidden',
      whiteSpace: 'normal',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 'var(--typographyMaxLines)',
      WebkitBoxOrient: 'vertical',
      maxHeight:
        'calc(var(--typographyMaxLines) * var(--typographyLineHeight))',
    },
    'variant-inherit': {
      ...typographyConstants.inherit,
    },
    'variant-footerLicense': {
      ...typographyConstants.footerLicense,
    },
    'variant-input': {
      ...typographyConstants.input,
    },
    'variant-navigationLinks': {
      ...typographyConstants.navigationLinks,
    },
    'variant-body': {
      ...typographyConstants.body,
    },
    'variant-directoryCardDetailTitle': {
      ...typographyConstants.directoryCardDetailTitle,
    },
    'variant-headerSmallest': {
      ...typographyConstants.headerSmallest,
    },
    'variant-categories': {
      ...typographyConstants.categories,
    },
    'variant-cardContent': {
      ...typographyConstants.cardContent,
    },
    'variant-categoryFilters': {
      ...typographyConstants.categoryFilters,
    },
    'variant-footerLinks': {
      ...typographyConstants.footerLinks,
    },
    'variant-headerSmall': {
      ...typographyConstants.headerSmall,
    },
    'variant-quote': {
      ...typographyConstants.quote,
    },
    'variant-cardTitle': {
      ...typographyConstants.cardTitle,
    },
    'variant-directoryCardTitle': {
      ...typographyConstants.directoryCardTitle,
    },
    'variant-header': {
      ...typographyConstants.header,
    },
    noMargin: {
      margin: 0,
    },
  },
  { name: 'typography' },
);
