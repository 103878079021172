// // button border gradient
// // nav indicator line bg
// #0095b8 -> #9169ff

// // button bg
// // headings
// #101b22

// // footer bg
// #00171b

// // mobile footer divider lines
// #1d3135

// // filters bg
// // hovered button bg
// #ebf0f5

// // filters button
// #dde6ee

// // paragraph text
// // category border
// // input border
// #666666

// // placeholder
// #999999

// // inverted text
// #ffffff

const spot1 = '#0095b8';
const spot2 = '#9169ff';
const transparent = 'rgba(0,0,0,0)';

const primary = {
  primary050: 'rgba(228, 230, 231, 1)', // #e4e6e7
  primary100: 'rgba(187, 193, 194, 1)', // #bbc1c2
  primary200: 'rgba(142, 152, 154, 1)', // #8e989a
  primary300: 'rgba(97, 111, 114, 1)', // #616f72
  primary400: 'rgba(63, 80, 83, 1)', // #3f5053
  primary500: 'rgba(29, 49, 53, 1)', // #1d3135 // in use
  primary600: 'rgba(16, 27, 34, 1)', // #101b22 // in use
  primary700: 'rgba(0, 15, 19, 1)', // #000F13 // in use
  primary700_20: 'rgba(0, 15, 19, 0.2)', // #000F13 // in use
  primary800: 'rgba(6, 17, 25, 1)', // #061119 // in use
  primary900: 'rgba(0, 12, 19, 1)', // #000c13 // in use
  primary900_10: 'rgba(0, 12, 19, 0.1)', // #000c13 // in use
  primary900_20: 'rgba(0, 12, 19, 0.2)', // #000c13 // in use
};

const neutrals = {
  neutral050: 'rgba(255, 255, 255, 1)', // #ffffff // in use
  neutral100: 'rgba(235, 240, 245, 1)', // #ebf0f5 // in use
  neutral100_30: 'rgba(235, 240, 245, 0.3)', // #ebf0f5 // in use
  neutral200: 'rgba(221, 230, 238, 1)', // #dde6ee // in use
  neutral300: 'rgba(187, 192, 196, 1)', // #bbc0c4 // in use
  neutral400: 'rgba(153, 153, 153, 1)', // #999999 // in use
  neutral500: 'rgba(125, 125, 125, 1)', // #7d7d7d
  neutral600: 'rgba(90, 90, 90, 1)', // #5A5A5A // in use
  neutral700: 'rgba(73, 73, 73, 1)', // #494949
  neutral800: 'rgba(56, 56, 56, 1)', // #383838
  neutral900: 'rgba(0, 0, 0, 1)', // #000000
};

const highlightGradient = `linear-gradient(to right, ${spot1} 0%, ${spot2} 100%)`;
const imageTopGradient = `linear-gradient(to top, ${transparent} 20%, ${primary.primary600} 80%)`;
const loaderPrimaryGradient = `linear-gradient(90deg, ${primary.primary700_20} 35%, ${primary.primary700} 50%,  ${primary.primary700_20} 65%)`;
const loaderNeutralGradient = `linear-gradient(90deg, ${neutrals.neutral100_30} 35%, ${neutrals.neutral100} 50%,  ${neutrals.neutral100_30} 65%)`;

export default {
  theme: {
    ...primary,
    ...neutrals,
    spot1,
    spot2,
    transparent,
    error: '#f16b32',
  },
  context: {
    gradients: {
      highlightGradient,
      imageTopGradient,
    },
    loader: {
      primaryGradient: loaderPrimaryGradient,
      neutralGradient: loaderNeutralGradient,
    },
    inputs: {
      color: neutrals.neutral600,
      placeholder: neutrals.neutral400,
      background: neutrals.neutral050,
      border: neutrals.neutral600,
    },
    header: {
      background: primary.primary500,
      color: neutrals.neutral050,
    },
    body: {
      background: neutrals.neutral050,
      color: neutrals.neutral600,
    },
    modal: {
      background: primary.primary800,
      border: primary.primary900,
      color: neutrals.neutral050,
    },
    footer: {
      background: neutrals.neutral900,
      color: neutrals.neutral050,
    },
    typography: {
      headings: {
        color: primary.primary600,
      },
    },
  },
};
