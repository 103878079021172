import { createContext, useMemo } from 'react';

import useMediaQueryManagement from './hooks/useMediaQueryManagement';
import {
  MediaQueryManagementContextProps,
  MediaQueryManagementContextValue,
} from './types';

export const MediaQueryManagementContext =
  createContext<MediaQueryManagementContextValue>({
    isXs: true,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isTablet: false,
    isDesktop: false,
    largeUpString: false,
  });

export const MediaQueryManagementProvider = ({
  children,
}: MediaQueryManagementContextProps) => {
  const mediaQueryManagement = useMediaQueryManagement();
  const value = useMemo(() => mediaQueryManagement, [mediaQueryManagement]);

  return (
    <MediaQueryManagementContext.Provider value={value}>
      {children}
    </MediaQueryManagementContext.Provider>
  );
};
