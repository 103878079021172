import createKeyValueStore from '../../keyValueStore';
import { cancelableRequestFactory } from '../cancelableRequests';
import { constructIdempotencyKey } from '../logic';
import { RequestConfig, RequestPerformer, Response } from '../types';
import { RequestCacheReset } from './types';

export const cachedRequestFactory = (): [
  RequestPerformer,
  RequestCacheReset,
] => {
  const responseCache = createKeyValueStore<Response<unknown>>();
  const cancelableRequest = cancelableRequestFactory();

  const resetCache = () => {
    responseCache.resetStore();
  };

  const cachedRequest = async <ResponseType>(config: RequestConfig) => {
    const { skipCache } = config;
    const idempotencyKey = constructIdempotencyKey(config);
    const cacheValue = responseCache.getValueForKey(
      idempotencyKey,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    );

    if (cacheValue !== undefined && !skipCache) {
      return Promise.resolve(cacheValue as Response<ResponseType>);
    }

    const response = await cancelableRequest<ResponseType>(config);

    if (config.method === 'GET' && response.status === 200) {
      responseCache.setValueForKey(idempotencyKey, response);
    }

    return response;
  };

  return [cachedRequest, resetCache];
};
