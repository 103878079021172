const toast = 2500;
const modal = 2000;

export default {
  toast,
  modal,
  backdrops: {
    modal: modal - 1,
  },
  fixedHeaderNav: 100,
  fixedMobileNav: 1000,
};
