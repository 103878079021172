/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import Rollbar from 'rollbar';
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider,
  RollbarContext,
  useRollbar,
} from '@rollbar/react';

import ErrorComponent from '../../components/ErrorComponent';
import config from '../../constants/config';
import { isDevelopment } from '../../constants/environment';
import { rollbarEnvironment } from './logic';
import {
  ErrorBoundaryProps,
  ErrorCaptureProviderProps,
  ErrorSpaceProps,
  UseErrorCapture,
} from './types';

export const useErrorCapture = useRollbar as UseErrorCapture;

const rollbarConfig: Rollbar.Configuration = {
  enabled: !isDevelopment,
  accessToken: config.keys.rollbar,
  environment: rollbarEnvironment(),
  // captureUncaught: true,
  // captureUnhandledRejections: true,
  payload: {
    context: 'root',
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <RollbarErrorBoundary fallbackUI={ErrorComponent as any}>
    {children}
  </RollbarErrorBoundary>
);

export const ErrorSpace = ({ children, space, ...props }: ErrorSpaceProps) => (
  <RollbarContext {...props} context={space}>
    {children}
  </RollbarContext>
);

export const ErrorCaptureProvider = ({
  children,
}: ErrorCaptureProviderProps) => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Provider>
);
