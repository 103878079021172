export const isDevelopment =
  typeof process.env.NODE_ENV === 'undefined' ||
  process.env.NODE_ENV === 'development' ||
  window?.location?.hostname === 'localhost' ||
  window?.location?.hostname?.indexOf('192') === 0 ||
  window?.location?.hostname?.indexOf(
    'development.criticalthinkingalliance.org',
  ) >= 0;

export const isLanding = process.env.REACT_APP_RELEASE_SPACE === 'landing';
