import { EMPTY_ARR, EMPTY_OBJ } from '../../constants/emptys';
import deepFreeze from '../helpers/deepFreeze';
import logger from '../logger';
import {
  ItemSubscriptionCallback,
  KeyValueStore,
  StoreSubscriptionCallback,
  Unsubscribe,
} from './types';

const createKeyValueStore = <T = unknown>(
  initialState = {},
): KeyValueStore<T> => {
  let cache: Record<string, T> = deepFreeze(initialState);
  let storeSubscriptions: StoreSubscriptionCallback<T>[] =
    EMPTY_ARR as StoreSubscriptionCallback<T>[];
  let itemSubscriptions: Record<
    string,
    ItemSubscriptionCallback<T | undefined>[]
  > = EMPTY_OBJ as Record<string, ItemSubscriptionCallback<T | undefined>[]>;

  const notifySubscribers = (key: string, value: T | undefined) => {
    const itemSubscribers = itemSubscriptions[key] || EMPTY_ARR;
    itemSubscribers.forEach((itemSubscriber) => {
      itemSubscriber(value);
    });
    storeSubscriptions.forEach((storeSubscription) => {
      storeSubscription(cache);
    });
  };

  const getValueForKey = (key: string): T | undefined => cache[key];

  const setValueForKey = (key: string, value: T | undefined) => {
    cache = deepFreeze({
      ...cache,
      [key]: value,
    });
    notifySubscribers(key, value);
  };

  const deleteValueForKey = (key: string) => {
    const { [key]: deleted, ...newCache } = cache;
    cache = deepFreeze(newCache);
    notifySubscribers(key, undefined);
  };

  const subscribeToItem = (
    key: string,
    callback: ItemSubscriptionCallback<T | undefined>,
  ): Unsubscribe => {
    const closedCallback = ((item: T | undefined) => {
      callback(item);
    }) as ItemSubscriptionCallback<T | undefined>;
    itemSubscriptions = {
      ...itemSubscriptions,
      [key]: [...(itemSubscriptions[key] || []), closedCallback],
    };
    logger.log('on subscription firing', key, getValueForKey(key));
    closedCallback(getValueForKey(key));
    return () => {
      itemSubscriptions = {
        ...itemSubscriptions,
        [key]: itemSubscriptions[key].filter(
          (itemSubscription) => itemSubscription !== closedCallback,
        ),
      };
    };
  };

  const subscribeToStore = (
    callback: StoreSubscriptionCallback<T>,
  ): Unsubscribe => {
    const closedCallback = ((items: Record<string, T>) => {
      callback(items);
    }) as StoreSubscriptionCallback<T>;
    storeSubscriptions = [...storeSubscriptions, closedCallback];
    closedCallback(cache);
    return () => {
      storeSubscriptions = storeSubscriptions.filter(
        (storeSubscription) => storeSubscription !== closedCallback,
      );
    };
  };

  const resetStore = () => {
    const keys = Object.keys(cache);
    cache = deepFreeze(initialState);
    keys.forEach((key) => {
      const itemSubscribers = itemSubscriptions[key] || EMPTY_ARR;
      itemSubscribers.forEach((itemSubscriber) => {
        itemSubscriber(undefined);
      });
    });
    storeSubscriptions.forEach((storeSubscription) => {
      storeSubscription(cache);
    });
  };

  return {
    getValueForKey,
    setValueForKey,
    deleteValueForKey,
    subscribeToItem,
    subscribeToStore,
    resetStore,
  };
};

export default createKeyValueStore;
