import axios from 'axios';

import { addInterceptors, apiRequestBase } from '../logic';
import { RequestPerformer } from '../types';

const instance = axios.create();
addInterceptors(instance);

export const networkRequest = apiRequestBase(
  instance.request as RequestPerformer,
);
