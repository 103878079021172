/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestConfig } from '../../../../services/requests/types';
import { toastDurations } from '../../../ToastContext/constants';
import { ToastContextValue } from '../../../ToastContext/types';
import { ServiceRequestName } from '../../types';

export const getServiceBaseUrl = (serviceName: ServiceRequestName) => {
  if (serviceName === 'data') {
    return `${window.location.protocol}//${window.location.host}/${serviceName}`;
  }
  let baseUrl = `${window.location.protocol}//api.${serviceName}.${window.location.host}`;

  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.indexOf('192') === 0
  ) {
    // Override it for dev server
    baseUrl = `${window.location.protocol}//localhost:8080/api/${serviceName}`;
    // development server
    // baseUrl = `https://api.${serviceName}.development.criticalthinkingalliance.org`;
  }

  return baseUrl;
};

export const addServiceBaseUrlToConfig = (
  serviceName: ServiceRequestName,
  config: RequestConfig,
) => ({
  ...config,
  baseURL: getServiceBaseUrl(serviceName),
});

const removeStoryErrorText = (message = '') =>
  message.replace(/^story.+\[(.+)\]/, '$1');

const getPrettyErrorMessage = (errorObject: any) => {
  const body =
    removeStoryErrorText(errorObject?.response?.data?.errorText) ||
    errorObject.message;

  return {
    title: 'Error',
    body,
  };
};

export const launchErrorToast = (
  registerToast: ToastContextValue['registerToast'],
  error: any,
  idempotencyKey: string,
) => {
  const { title, body } = getPrettyErrorMessage(error);

  registerToast(`requestError-${idempotencyKey}`, {
    duration: toastDurations.error,
    variant: 'error',
    title,
    body,
  });
};
